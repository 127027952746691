<template>
  <div>
    <search-tool :title="$t('userGroupList')" @search="search" @refresh="getList(currentPage)">
      <template #handle-buttons>
        <el-button size="small" type="primary" @click="$router.push('/admin/group/add')">
          {{ $t("handle.add") }}
        </el-button>
      </template>
    </search-tool>

    <el-table :data="data" v-loading="loading">
      <el-table-column prop="name" :label="$t('name')">
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="group"></svg-icon>
            <div class="content">
              <div class="title">{{ scope.row.name }}</div>
              <span class="desc">
                {{ moment(scope.row.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="organizationUUID" :label="$t('belongOrganization')">
        <template slot-scope="">
          <div class="title">{{ organizationName }}</div>
          <div class="desc">{{ organization }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="description" :label="$t('description')"> </el-table-column>

      <el-table-column width="100" :label="$t('handle.handle')">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <button class="table-row-handle">
              <i class="el-icon-more-outline"></i>
            </button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="edit(scope.row)">
                  {{ $t("handle.edit") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item @click="deleteGroup(scope.row)">
                <div @click="deleteGroup(scope.row)">
                  {{ $t("handle.delete") }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="$t('handle.edit')" :visible.sync="dialogVisible" top="30px" width="1000px">
      <div v-loading="detailLoading">
        <group-form :form="form" ref="form" v-if="dialogVisible && !detailLoading" />
      </div>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="submitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>

    <el-pagination
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { groupList, groupEdit, groupDelete, groupDetail } from "api/group";
import GroupForm from "./Form.vue";
import { cloneDeep, isArray, includes } from "lodash";

export default {
  components: {
    GroupForm
  },

  data() {
    return {
      data: [],
      total: undefined,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,
      searchText: "",
      form: {},
      dialogVisible: false,
      submitLoading: false,
      detailLoading: true
    };
  },

  methods: {
    getList(page = 1, pageSize = this.currentPageSize, search = [this.searchText]) {
      this.loading = true;
      this.currentPage = page;

      groupList(this.organization, { page, pageSize, search }).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.data = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    search(searchText) {
      this.searchText = searchText;
      this.getList(1);
    },

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    edit(row) {
      this.dialogVisible = true;
      this.detailLoading = true;
      this.form = cloneDeep(row);

      groupDetail(this.organization, row.uuid).then(response => {
        if (response.code === 0) {
          this.detailLoading = false;

          let { groupUserBindings, groupUserRoleBindings } = response.data;
          this.$set(this.form, "bindings", {
            groupUserBindings,
            groupUserRoleBindings
          });

          this.$nextTick(() => {
            if (groupUserBindings)
              this.$set(
                this.$refs["form"],
                "groupUserBindings",
                groupUserBindings.map(item => item.userUUID)
              );

            if (groupUserRoleBindings)
              groupUserRoleBindings.forEach(item => {
                this.$set(this.$refs["form"].groupUserRoleBindings, item.providerUUID, item.roleUUID);
                this.$set(this.$refs["form"].effectBindings, item.providerUUID, item.effect);
              });
          });
        }
      });
    },

    submit() {
      this.$refs["form"].$refs["form"].validate(valid => {
        if (valid) {
          let groupUserBindings = this.$refs["form"].groupUserBindings;
          let groupUserRoleBindings = this.$refs["form"].groupUserRoleBindings;
          let effectBindings = this.$refs["form"].effectBindings;

          if (!this.form.bindings.groupUserRoleBindings) this.$set(this.form.bindings, "groupUserRoleBindings", []);
          if (!this.form.bindings.groupUserBindings) this.$set(this.form.bindings, "groupUserBindings", []);

          let existGroupUserRoleBindings =
            this.form.bindings.groupUserRoleBindings.length > 0
              ? this.form.bindings.groupUserRoleBindings.map(item => item.providerUUID)
              : [];

          for (let key in groupUserRoleBindings) {
            if (includes(existGroupUserRoleBindings, key)) {
              this.form.bindings.groupUserRoleBindings.forEach(item => {
                let { roleUUID, providerUUID, effect } = item;

                if (providerUUID === key) {
                  if (groupUserRoleBindings[providerUUID] != roleUUID) {
                    this.$set(item, "roleUUID", groupUserRoleBindings[providerUUID]);
                    this.$set(item, "operation", "UPDATE");
                  }

                  if (effectBindings[providerUUID] != effect) {
                    this.$set(item, "effect", effectBindings[providerUUID]);
                    this.$set(item, "operation", "UPDATE");
                  }
                }
              });
            } else {
              this.form.bindings.groupUserRoleBindings.push({
                providerUUID: key,
                roleUUID: groupUserRoleBindings[key],
                effect: effectBindings[key],
                groupUUID: this.form.uuid,
                organizationUUID: this.organization
              });
            }
          }

          if (isArray(this.form.bindings.groupUserBindings)) {
            if (this.form.bindings.groupUserBindings.length > 0) {
              this.form.bindings.groupUserBindings.forEach(item => {
                if (!includes(groupUserBindings, item.userUUID)) {
                  this.$set(item, "operation", "DELETE");
                }
              });
            }

            groupUserBindings.forEach(item => {
              if (
                !includes(
                  this.form.bindings.groupUserBindings.length > 0
                    ? this.form.bindings.groupUserBindings.map(item => item.userUUID)
                    : [],
                  item
                )
              ) {
                this.form.bindings.groupUserBindings.push({
                  userUUID: item,
                  groupUUID: this.form.uuid,
                  organizationUUID: this.organization
                });
              }
            });
          }

          this.submitLoading = true;

          groupEdit(this.form).then(response => {
            this.submitLoading = false;
            if (response.code === 0) {
              this.getList(this.currentPage);
              this.dialogVisible = false;
              this.$store.dispatch("app/getUserInfo");
            }
          });
        }
      });
    },

    deleteGroup(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          groupDelete(this.organization, item.uuid).then(response => {
            if (response.code === 0) {
              this.getList(this.currentPage);
              this.$store.dispatch("app/getUserInfo");
            }
          });
        })
        .catch(() => {});
    }
  },

  mounted() {
    this.getList();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    },

    organizationName() {
      return this.$store.state.app.organizationName;
    }
  }
};
</script>

<style></style>
